import { SubtitleLang } from "./SubtitleLang";

export class ModuleItem {
    ID: any = 'N/A';
    VolumeID: number;
    Title: string;
    TitleNormalized: string;
    VideoUrl: string = '';
    SortOrder: any;
    AllowGuestPreview: boolean;
    KeyWords: string;
    Transcripts: string;
    AssetKey: string;
    Objectives: any;
    AllowLinkedClipImage: boolean;
    VideoLength: any;
    Expert: string;
    ExpertNormalized: string;
    ImageUrl: string;
    AmazonIsUploaded: boolean;
    BrightCoveID: string;
    VolumeStatus: string;
    Tags:string;
    VideoSubcategories?:string;
    AssociatedVolumeID?:number;
    MatchedVideoID?:number;
}
