import { MarathonScheduleItem } from './../Objects/MarathonScheduleItem';
// Angular Things
import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as momenttz from 'moment-timezone';

// Services
import { CategoriesService } from '../../categories/categories.service';
import { UtilitiesService } from '../../shared/utilities.service';
import { ConfirmService } from '../../shared/confirm.service';
import { PopupService } from '../../shared/popup.service';
import { EmsConfig } from '../../shared/emsConfig';
import { CmsService } from '../../cms/cms.service';
import { AccountsService } from 'app/accounts/accounts.service';

// Classess
import { Expert } from '../../shared/expert';
import { Event } from '../../shared/event';
import { ConnexService } from '../connex.service';
import { Categories } from '../../categories/categories';
import { LanguageItem } from 'app/shared/objects/languages';
import { AteParticipatingClient } from '../Objects/AteParticipatingClient';

@Component({
  selector: 'ate-event-edit',
  templateUrl: 'ate-event-edit.component.html',
  providers: [ConnexService]
})
export class ATEEventEditComponent implements OnInit, OnDestroy {
  @HostBinding('class') classAttribute: string = 'ate-event-edit';
  // variables for getting account id from url
  id: number;

  private sub: any;

  confirmData: {};
  confirmEventData: any;
  confirmAction: string;
  confirmSubscription: Subscription;

  // expert search variables
  expertList: any;  // for expert lists in drop down
  filteredExpertsToSearch: any[] = [];

  eventDetails: Event = new Event();  // for EVENTDETAILS
  public categoryList: Categories[]; // for category lists in drop down
  public currentEnrollment: number = 0;
  public marathonScheduleList: MarathonScheduleItem[];
  // for event image
  imagePath: string;
  expertImgPath: string;
  heroImagePath: string;
  imageName: string;
  imagePreview: boolean = true;

  public events: Event[] = [];
  public sameMonthEvents:Event[]=[];
  private errorMessage: string;
  nbspTip: string = 'Add: &amp;nbsp; instead of a regular space if you want the text to remain on one line.<br>Example: In French a space is put inbetween the word and colon.';
  eventImgTip: string = 'Needs to be uploaded by a designer to "[imagePath]" (size 500px X 490px)';
  expertImgTip: string = 'Needs to be uploaded by a designer to "[imagePath]" (size 120px X 120px)';
  heroImgTip: string = 'Needs to be uploaded by a designer to "[imagePath]" (size 720px X 375px)';
  matchedAteErrorMessage: string= 'Please check if the French webchat has already been matched!';
  //
  dt: string;
  eventStartDateTime: Date;
  startTime: string;
  endTime: string;
  marathonStart: Date;
  marathonEnd: Date;
  newEventID: any;
  fullLanguage: string;
  languages: any;

  public allClientsWithRestrictedAtes: any[] = [];
  public participatingClients: AteParticipatingClient[] = [];
  public addNewClient: boolean;
  
  transcriptPreviewLink: string;
  showPreviewLink: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private _cmsService: CmsService,
    private _categoriesService: CategoriesService,
    private _connexService: ConnexService,
    private _utilitiesService: UtilitiesService,
    private _confirmService: ConfirmService,
    private _popupService: PopupService,
    public _emsConfig: EmsConfig,
    private _accountService: AccountsService
  ) {
    this.confirmSubscription = _confirmService.actionConfirmed$.subscribe(
      response => {
        if (response.confirmed) {
          if (this.confirmAction === 'updateEvent') {
            this.eventSubmitForm(this.confirmEventData);
          }
        }
      }
    );
  }

  // ============================
  // confirm & popup modal stuff
  // ============================
  openConfirmDialog(
    component: string,
    action: string,
    eventData: any,
    message: string
  ): void {
    this.confirmAction = action;
    this.confirmEventData = eventData;

    this.confirmData = { component: component, message: message };
    this._confirmService.updateNotification(this.confirmData);
  }

  // ========================
  // get lists for dropdowns
  // ========================

  getCategories(): void {
    this._categoriesService.getAllCategories().subscribe(CategoryList => {
      this.categoryList = CategoryList['categories'];
      console.log(CategoryList);
    });
  }

  getExpertListDropdown() {
    this._cmsService.getAllExperts().subscribe(Expert => {
      console.log(Expert);
      this.expertList = Expert;
      for (let i = 0; i < this.expertList.length; i++) {
        this.expertList[i].NormalizedName = this._utilitiesService.normalizeTerm(this.expertList[i].ExpertName.English);
      }

      // set experts returned from DB to filterable list
        this.filteredExpertsToSearch = this.expertList.map(w => {
        return {
          ID: w.ExpertID,
          Name: w.ExpertName.English
        };
      });
    });
  }

  // expert search
  lookupExpert(e) {
    let searchVal = e.toLocaleLowerCase();
    this.filteredExpertsToSearch = this.expertList.filter(
      i => (
        i.NormalizedName).toLocaleLowerCase().indexOf(searchVal) > -1)
        .map(w => {
          return {
            ID: w.ExpertID, Name: w.ExpertName.English
          };
        });
  }

  // ========================
  // get details for event
  // ======================

  getEventDetails() {
    this._connexService.getAteEventbyID(this.id).subscribe(Event => {
      console.log(Event);
      this.eventDetails = Event as Event;
      if (this.id === -1) {
        this.eventDetails.EventID = 'N/A';
        this.eventDetails.Language = 'EN';
        this.eventStartDateTime = new Date();
        this.eventDetails.ChatStartTime = moment(this.eventStartDateTime).format('YYYY-MM-DDTHH:mm');
      } else {
        this.eventStartDateTime = this.eventDetails.ChatStartTime;
        // get the image name & path
        this.imageName = 'expert-pic' + this.id.toString() + '.jpg';
        this.imagePath = this._emsConfig.assetPath + '/ImageAssets/originals/ates/' + this.imageName;
        this.eventImgTip = this.eventImgTip.replace('[imagePath]', this.imagePath);

        this.expertImgPath = this._emsConfig.assetPath + '/ImageAssets/originals/experts/' + this.eventDetails.Expert.PhotoUrl;
        this.expertImgTip = this.expertImgTip.replace('[imagePath]', this.expertImgPath);

        this.heroImagePath = this._emsConfig.assetPath + '/ImageAssets/originals/atebanner/' + this.imageName;
        this.heroImgTip = this.heroImgTip.replace('[imagePath]', this.heroImagePath);
        this.imagePreview = true;
        
        // show preview link for existing ATE
        this.showPreviewLink = true;            
        this.transcriptPreviewLink = `${this._emsConfig.assetPreviewLink}/asktheexpert/event/${this.eventDetails.AssetKey}`;
      }
      this.fullLanguage = this.eventDetails.Language == 'EN' ? 'English' : 'French';
      this.getMarathonList();
      this.getParticipatingClients();
    });
  }
  marathonCheckbox(values: any) {
    if (values.currentTarget.checked) {
      this.getMarathonList();
    }
  }
  restrictedAteCheckbox(values: any) {
    if (values.currentTarget.checked) {
      this.eventDetails.RestrictedAte = values.currentTarget.checked;
    }
  }
  eventLanguage(values: any) {
    //Refresh marathon schedulelist if lang changes
    if (this.eventDetails.For_Marathon) {
      this.fullLanguage = this.eventDetails.Language == 'EN' ? 'English' : 'French';
      this.getMarathonList();
    }
  }
  getMarathonList() {
    if (!this.eventDetails.For_Marathon)
      return;
    this._connexService.getATEMarathonScheduleList(this.eventDetails.Language).subscribe(result => {
      this.marathonScheduleList = result;
    });
  }

  // get the current enrollment for an event
  private getCurrentEnrollment(id: number) {
    this._connexService.getWebchatEntrollmentByID(id).subscribe(result => {
      this.currentEnrollment = result['enrollment'];
    });
  }


  // ==============================
  // get & set event date in calendar
  // ==============================
  get ChatStartTime(): string {
    //2022-05-18T19:30:00-04:00
    return moment(this.eventDetails.ChatStartTime).format('YYYY-MM-DDTHH:mm');  // used moment to format the datetime in correct format like in DB
  }
  set ChatStartTime(value: string) {
    this.startTime = moment(new Date(value)).format('YYYY-MM-DDTHH:mm');
    var withOffset = momenttz(this.startTime).tz("America/Toronto").format('YYYY-MM-DDTHH:mmZ');
    console.log('strt time with  offset :', withOffset);
    this.eventDetails.ChatStartTime = withOffset;
  }

  get ChatEndTime(): string {
    return moment(this.eventDetails.ChatEndTime).format('YYYY-MM-DDTHH:mm');  // used moment to format the datetime in correct format like in DB
  }
  set ChatEndTime(value: string) {
    this.endTime = moment(new Date(value)).format('YYYY-MM-DDTHH:mm');
    var withOffset = momenttz(this.endTime).tz("America/Toronto").format('YYYY-MM-DDTHH:mmZ');
    console.log('end time with  offset :', withOffset);
    this.eventDetails.ChatEndTime = withOffset;
    this.validateEndDate();
  }
  // ========================
  // submit the info
  // ========================
  eventSubmitForm(eventDetails): void {

    //Make sure category is selected for the event.
    if (eventDetails.CategoryID === 0) {
      this._popupService.updateNotification({
        message: 'Please select a category!',
        error: true
      });
      return;
    }
    if (!this.validateEndDate()) {
      return;
    }
    if (
      this._utilitiesService.isBlank(
        [eventDetails.EventTopic]
      ) || eventDetails.Expert.ExpertID === 0
    ) {
      this._popupService.updateNotification({
        message: this._emsConfig.text.EMS_Content.Complete_Fields_Error,
        error: true
      });
    } else {
      if (eventDetails.EventID === 'N/A') {
        eventDetails.EventID = -1;
      }
      eventDetails.RestrictedToClients = this.participatingClients.length > 0 ? this.participatingClients.map(x => x.ClientName).join(',') : null;
      console.log(this.eventDetails);
      this._connexService.updateCreateEvent(eventDetails).subscribe(EventID => {
        if (EventID !== -1) {
          if (this.id === -1) {
            this.newEventID = EventID;
            this.location.go('/asktheexpert/event-edit/' + this.newEventID);
            this.id = this.newEventID;
          } else {
            console.log('updated the event-----' + EventID);
          }
          this.getEventDetails();
          this._popupService.updateNotification({
            message: this._emsConfig.text.EMS_General.Popup_Success,
            success: true
          });
        } else {
          this._popupService.updateNotification({
            message: `${this._emsConfig.text.EMS_General.Popup_Error} ${this.matchedAteErrorMessage}`,
            error: true
          });
        }
      }, error => {
        if (error.error.message) {
          this._popupService.updateNotification({
            message: error.error.message,
            error: true,
            duration: 10000
          });
        }
      });
    }
  }
  validateEndDate() {
    if (moment(new Date(this.ChatEndTime)).isBefore(moment(new Date(this.ChatStartTime)))) {
      this._popupService.updateNotification({
        message: "The event end date can not be before the event start date.",
        error: true
      });
      return false;
    }
    return true;
  }
  //load all the languages supported by the LifeSpeak
  getAllLanguages() {
    this._cmsService.getLanguages().subscribe(languages => {
      this.languages = languages as LanguageItem[];
    });
  }

  private getParticipatingClients(): void {
    if (this.id === -1)
      return;

    this._connexService.getAteParticipatingClientsByEventID(this.id).subscribe(data => {
      console.log(data);
      this.participatingClients = data;
      //organize clients list
      this.organizeClientList();
    });
  }
  private getAllRestrictedAteClients(): void {
    this._accountService.getClientsWithRestrictedAtes().subscribe((accounts: any) => {
      //map/add properties to bind with the checkbox
      accounts.map((client) => {
        client.Selected = false;
        client.ClientName = client.OrganizationName;
        return client;
      })
      console.log(accounts);
      this.allClientsWithRestrictedAtes = accounts;
    });
  }
  private organizeClientList(): void {
    //remove participating clients from the list of all available clients.
    this.participatingClients.forEach(client => {
      this.allClientsWithRestrictedAtes = this.allClientsWithRestrictedAtes.filter(x => x.ClientName.toLowerCase() !== client.ClientName.toLowerCase());
    })

  }

  clientSelectionHandler(client: any, event: any): void {
    console.log(event);
    let index = this.allClientsWithRestrictedAtes.findIndex(x => x.ClientName.toLowerCase() === client.ClientName.toLowerCase());
    if (index !== -1) {
      this.allClientsWithRestrictedAtes[index].Selected = event.target.checked;
    }
  }
  participatedClientHandler(client: any, event: any): void {
    console.log(event);
    let index = this.participatingClients.findIndex(x => x.ClientName.toLowerCase() === client.ClientName.toLowerCase());
    if (index !== -1) {
      this.participatingClients[index].Selected = event.target.checked;
    }
  }

  addClient(): void {
    console.log('add');

    //get all selected clients
    let selectedClients = this.allClientsWithRestrictedAtes.filter(client => client.Selected);
    let selectedClientNames = [];
    selectedClients.forEach(client => {

      //check if it's already selectedClients
      let index = this.participatingClients.findIndex(x => x.ClientName.toLowerCase() === client.ClientName.toLowerCase());
      if (index !== -1) {
        return;
      }
      client.Selected = false;
      this.participatingClients.push(client);
      selectedClientNames.push(client.ClientName);
    });
    //remove selected iteam from the array.
    selectedClientNames.forEach(itemToremove => {
      let index = this.allClientsWithRestrictedAtes.findIndex(x => x.ClientName.toLowerCase() === itemToremove.toLowerCase());
      if (index !== -1) {
        this.allClientsWithRestrictedAtes.splice(index, 1);
      }
    });
    console.log(selectedClients);
    console.log(this.participatingClients);
  }

  removeClient(): void {
    console.log('remove');
    //add back removed clients to list of all the clients.
    let removedClients = this.participatingClients.filter(client => client.Selected);
    console.log(removedClients);
    removedClients.forEach(removedClient => {

      removedClient.Selected = false;
      this.allClientsWithRestrictedAtes.push(removedClient);

      let index = this.participatingClients.findIndex(x => x.ClientName.toLowerCase() === removedClient.ClientName.toLowerCase());
      if (index !== -1) {
        this.participatingClients.splice(index, 1);
      }
    });
    //Sort all the clients array
    this.allClientsWithRestrictedAtes.sort((a, b) => a.ClientName.localeCompare(b.ClientName));
  }

  //load all ATE events
  getAllAteEvents() {
    this._connexService.getAllExpertEvents(null)
      .subscribe(response => {
        console.log(response);
        this.events = response;
        // ATEs that take place in the same month as the current English Webchat
        this.sameMonthEvents = this.events.filter(x => (this.eventDetails.For_Marathon? x.For_Marathon:!x.For_Marathon) && x.Language.toLowerCase() === (this.eventDetails?.Language.toLocaleLowerCase()==='en'? 'fr':'en') && moment(this.eventStartDateTime).format('YYYY-MM') == moment(x.ChatStartTime).format('YYYY-MM'));
        // List of other Webchats
        this.events = this.events.filter(x => (this.eventDetails.For_Marathon? x.For_Marathon:!x.For_Marathon) && x.Language.toLowerCase() === (this.eventDetails?.Language.toLocaleLowerCase()==='en'? 'fr':'en') && moment(this.eventStartDateTime).format('YYYY-MM') != moment(x.ChatStartTime).format('YYYY-MM'));
      },
        err => this.errorMessage = <any>err,
      );
  }


  // ===================================
  // performing component initialization
  // ===================================
  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.id = parseInt(params['id']);


      if (this.id === -1) {
        this.imagePreview = false; // will not show the attribute image name
      } else {
        this.imagePreview = true;
        this.getCurrentEnrollment(this.id);
      }
      this.getEventDetails();
      this.getCategories();
      this.getExpertListDropdown();
      this.getAllLanguages();
      this.getAllRestrictedAteClients();
      this.getAllAteEvents() 
    });
  }

  // =============================
  // performing component cleanup
  // ==============================
  ngOnDestroy() {
    this.sub.unsubscribe();
    this.confirmSubscription.unsubscribe();
  }
}
