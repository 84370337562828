
<div class="ate ems5">
  <div class="inner-page-main section-padding expert-details ate--event-edit">
    <syncstatus [assetID]="eventDetails?.EventID" [tableID]="9" [ateChatReady]="eventDetails?.IsTranslated"></syncstatus>
    <button class="btn--back back pointer" [routerLink]="['/asktheexpert']">&#8592; Back</button>
    <div class="module-details-container edit-event-details">
      <div class="ate__event-info">
        <div>
          <span><strong>Title:</strong> {{eventDetails?.EventTopic}}</span>
          <span><strong>Expert:</strong> {{eventDetails?.Expert?.ExpertName.English}}</span>
          <span><strong>Date:</strong> {{eventDetails?.EventDateString?.English}}</span>
          <span><strong>Language:</strong> {{eventDetails?.Language === 'FR' ? 'French' : 'English'}}</span>
          <span><strong>Moderated as:</strong> {{eventDetails.EventModerator}}</span>
        </div>
      </div>
      <div class="preview-link" *ngIf="showPreviewLink">
        <a [href]="transcriptPreviewLink" target="_blank">Preview Webchat Transcript</a>
      </div>
      <div class="basic-container module-details-container__info-container">
        <div class="flex-container">
          <div class="tabs card-box-shadow">
            <div class="tabs__container">
              <a [routerLink]="['/asktheexpert/event-edit, eventDetails?.EventID']" class="tab tab--active">
                <span class="page-header ls-subheading">Event Details</span>
              </a>
              <a [routerLink]="['/asktheexpert/moderate', eventDetails?.EventID]" class="tab">
                <span class="page-header f-dark-blue ls-subheading">Moderate</span>
              </a>
              <a [routerLink]="['/asktheexpert/question-edit', eventDetails?.EventID]" class="tab">
                <span class="page-header f-dark-blue ls-subheading">Edit Questions</span>
              </a>
            </div>
          </div>
          <div class="ate__users">
            Current Enrollment:&nbsp;<strong>{{ currentEnrollment }}</strong>
          </div>
        </div>
        <div class="details-container info-container__text-container ">
          <form class="addEditModuleForm">
            <div class="info-container__section-split">
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
                    </svg>
                  </div>
                  Event ID
                </h4>
                <p class="basic-info__text setting__text ls-subheading">{{eventDetails?.EventID}}</p>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading required-field">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                    </svg>
                  </div>
                  Expert Name
                </h4>
                <div class="basic-info__setting select-container--search">
                  <div class="input-container">
                    <mat-form-field>
                      <mat-select name="expertSelect" [(ngModel)]="eventDetails?.Expert.ExpertID">
                        <mat-form-field>
                          <!-- stop propagation required here to allow for spaces to used in the expert name when searching (default behaviour of space key in the select element is to choose a highlighted option from dropdown). Note, this will disable all default keyboard behaviour. Normally we would not do this for a11y but since EMS is internal will use for now -->
                          <input matInput placeholder="Search for Expert" (input)="lookupExpert($event.target.value)" (keydown)="$event.stopPropagation()" #expertInputFilter>
                        </mat-form-field>
                          <mat-option *ngFor="let expert of filteredExpertsToSearch" [value]="expert.ID">{{expert.Name}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading  required-field">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                    </svg>
                  </div>
                  Event Topic ({{eventDetails.Language}})
                </h4>
                <div class="input-container">
                  <input type="text" class="basic-info__text setting__text-input text-input" name="eventTopicEN"
                    [(ngModel)]="eventDetails && eventDetails.EventTopic">
                </div>
              </div>

              <div class="basic-info__setting" *ngIf="showPreviewLink">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z" />
                    </svg>
                  </div>
                  Asset Key
                </h4>
                <p class="basic-info__text setting__text ls-subheading">{{ eventDetails?.AssetKey }}</p>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M5,4V7H10.5V19H13.5V7H19V4H5Z" />
                    </svg>
                  </div>
                  Subtitle ({{eventDetails.Language}})
                </h4>
                <div class="input-container">
                  <textarea class="basic-info__text setting__textarea" name="eventDescriptionEN"
                    [(ngModel)]="eventDetails && eventDetails.EventDescription"></textarea>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <span class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                    </svg>
                  </span>Event Default Language
                </h4>
                <div class="select-container">
                  <select class="setting__select-input select-input" name="eventLanguage"
                    [(ngModel)]="eventDetails && eventDetails.Language" (change)="eventLanguage($event)">
                    <option *ngFor="let lang of languages" value={{lang.Code}}>
                      {{lang.Code}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="basic-info__setting dates-container">
                <div class="startDate-container">
                  <h4 class="basic-info__heading setting__heading ls-subheading required-field">
                    <div class="svg-icon svg-baseline">
                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                        <path
                          d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                      </svg>
                    </div>
                    Event Start Time
                  </h4>
                  <div class="input-container">
                    <input class="date-time-input" type="datetime-local" name="ESartD" value=""
                      [(ngModel)]="ChatStartTime">
                  </div>
                </div>

                <div class="endate-container">
                  <h4 class="basic-info__heading setting__heading ls-subheading required-field">
                    <div class="svg-icon svg-baseline">
                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                        <path
                          d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                      </svg>
                    </div>
                    Event End Time
                  </h4>
                  <div class="input-container">
                    <input class="date-time-input" type="datetime-local" name="EEndD" value="" [(ngModel)]="ChatEndTime">
                  </div>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                    </svg>
                  </div>
                  Category
                </h4>
                <div class="select-container">
                  <select class="setting__select-input select-input" name="ateCategory"
                    [(ngModel)]="eventDetails && eventDetails.CategoryID">
                    <option [ngValue]="0" disabled>Select the category</option>
                    <option [ngValue]="category.ID" *ngFor="let category of categoryList">{{category.Name.English}}
                    </option>
                  </select>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                    </svg>
                  </div>
                  Matched ATE
                </h4>
                <div class="select-container">
                  <select class="setting__select-input select-input" name="matched-ate" id="matched-ate" [disabled]="eventDetails.Language?.toUpperCase()==='FR'"
                    [(ngModel)]="eventDetails && eventDetails.MatchedEventID">
                    <option [ngValue]="null" disabled>Select a matched ATE event</option>
                    <option [ngValue]="0" *ngIf="eventDetails.MatchedEventID!=null">Deselect a matched Webchat</option>
                    <optgroup label="Matched French Webchat" *ngIf="sameMonthEvents?.length>0">
                      <option [ngValue]="item.EventID" *ngFor="let item of sameMonthEvents">{{item.EventTopic}}</option>
                    </optgroup>
                    <optgroup label="All French Webchat">
                      <option [ngValue]="item.EventID" *ngFor="let item of events">{{item.EventTopic}}</option>
                    </optgroup>
                  </select>
                </div>
              </div>

              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading ls-tooltip">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M5.5,7A1.5,1.5 0 0,1 4,5.5A1.5,1.5 0 0,1 5.5,4A1.5,1.5 0 0,1 7,5.5A1.5,1.5 0 0,1 5.5,7M21.41,11.58L12.41,2.58C12.05,2.22 11.55,2 11,2H4C2.89,2 2,2.89 2,4V11C2,11.55 2.22,12.05 2.59,12.41L11.58,21.41C11.95,21.77 12.45,22 13,22C13.55,22 14.05,21.77 14.41,21.41L21.41,14.41C21.78,14.05 22,13.55 22,13C22,12.44 21.77,11.94 21.41,11.58Z" />
                    </svg>
                  </div>
                  Event Moderator
                  <tooltip
                  [tooltipMessage]="'You can optionally add a custom event moderator name. This will change the default display name for anything the EMS moderator posts during a webchat. Ex: The opening remarks, Blog posts, etc.'">
                </tooltip>
                </h4>
                <div class="input-container">
                  <!-- TODO - update input to mat-autocomplete -->
                  <input type="text" class="basic-info__text setting__text-input text-input" name="eventModerator"
                  [(ngModel)]="eventDetails && eventDetails.EventModerator">
                </div>
              </div>

              <div class="basic-info__setting" *ngIf="id !== -1">
                <h4 class="basic-info__heading setting__heading ls-subheading ls-tooltip">
                  <div class="svg-icon svg-baseline">
                    <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                      <path
                        d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z" />
                    </svg>
                  </div>
                  Chat ready for ASK page?
                  <tooltip
                    [tooltipMessage]="'The webchat questions have been edited and are ready to display on the ASK page.'">
                  </tooltip>
                </h4>
                <div class="basic-info__checkbox-container">
                  <div class="checkbox-container">
                    <div class="event-checkbox">
                      <input name="eventWebChat" [(ngModel)]="eventDetails && eventDetails.IsTranslated" type="checkbox">
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!--second half-->
            <div class="info-container__section-split">




              <!-- <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z"
                      />
                    </svg>
                  </div>
                  Event Code <tooltip [tooltipMessage]="_emsConfig.text?.EMS_Content.Event_Code_Tooltip"></tooltip>
                </h4>
                <div class="input-container">
                  <textarea class="basic-info__text setting__text-input textarea-input" rows="4" name="noteUrl" [(ngModel)]="eventDetails && eventDetails.EventCode"></textarea>
                </div>
              </div> -->

              <div class="basic-info__setting upload-setting image-upload__setting" *ngIf="id !== -1">
                <div>
                  <h4 class="basic-info__heading setting__heading ls-subheading">
                    <div class="svg-icon svg-baseline">
                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                        <path
                          d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                      </svg>
                    </div>
                    Event Tile Image <tooltip [tooltipMessage]="eventImgTip"></tooltip>
                  </h4>
                  <div class="event-image-container">
                    <img [src]="imagePath" alt="Image not uploaded ({{imageName}})">
                  </div>
                </div>
                <div>
                  <h4 class="basic-info__heading setting__heading ls-subheading">
                    <div class="svg-icon svg-baseline">
                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                        <path
                          d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                      </svg>
                    </div>
                    Livechat Expert Image <tooltip [tooltipMessage]="expertImgTip"></tooltip>
                  </h4>
                  <div class="event-image-container">
                    <img [src]="expertImgPath" alt="Image not uploaded ({{eventDetails?.Expert.PhotoUrl}})">
                  </div>
                </div>
              </div>
              <div class="hero-image-container" *ngIf="id !== -1">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z" />
                    </svg>
                  </div>
                  Email Hero Image <tooltip [tooltipMessage]="heroImgTip"></tooltip>
                </h4>
                <div class="event-image-container">
                  <img [src]="heroImagePath" alt="Image not uploaded ({{imageName}})">
                </div>
              </div>

              <!-- <div class="basic-info__setting" *ngIf="imagePreview">
                <h4 class="basic-info__heading setting__heading ls-subheading ">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path d="M8.5,13.5L11,16.5L14.5,12L19,18H5M21,19V5C21,3.89 20.1,3 19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19Z"
                      />
                    </svg>
                  </div>
                  Image File Name
                </h4>
                <div class="expert-image-path">{{imageName}}</div>
              </div> -->

              <div class="basic-info__setting" [hidden]="eventDetails && eventDetails.RestrictedAte">
                <h4 class="basic-info__heading setting__heading ls-subheading ls-tooltip">
                  <div class="svg-icon svg-baseline">
                    <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                      <path
                        d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z" />
                    </svg>
                  </div>
                  Marathon Chat
                  <tooltip [tooltipMessage]="'Check if this chat will be part of a Mental Health Marathon'"></tooltip>
                </h4>
                <div class="basic-info__checkbox-container">
                  <div class="checkbox-container">
                    <div class="event-checkbox">
                      <input name="eventForMarathon" [(ngModel)]="eventDetails && eventDetails.For_Marathon"
                        type="checkbox" (change)="marathonCheckbox($event)">
                    </div>
                  </div>
                </div>
              </div>
              <div class="basic-info__setting" [hidden]="eventDetails && eventDetails.For_Marathon">
                <h4 class="basic-info__heading setting__heading ls-subheading ls-tooltip">
                  <div class="svg-icon svg-baseline">
                    <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                      <path
                        d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z" />
                    </svg>
                  </div>
                  Client Restricted Chat
                  <tooltip [tooltipMessage]="'Check if this chat is only restricted to participating clients'"></tooltip>
                </h4>
                <div class="basic-info__checkbox-container">
                  <div class="checkbox-container">
                    <div class="event-checkbox">
                      <input name="eventForRAte" [(ngModel)]="eventDetails.RestrictedAte"
                        type="checkbox" (change)="restrictedAteCheckbox($event)">
                    </div>
                  </div>
                </div>
              </div>
              <div class="basic-info__setting">
                <h4 class="basic-info__heading setting__heading ls-subheading">
                  <div class="svg-icon svg-baseline">
                    <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24">
                      <path
                        d="M20,16V10H22V16A2,2 0 0,1 20,18H8C6.89,18 6,17.1 6,16V4C6,2.89 6.89,2 8,2H16V4H8V16H20M10.91,7.08L14,10.17L20.59,3.58L22,5L14,13L9.5,8.5L10.91,7.08M16,20V22H4A2,2 0 0,1 2,20V7H4V20H16Z" />
                    </svg>
                  </div>
                  Include in Content Fusion?
                </h4>
                <div class="basic-info__checkbox-container">
                  <div class="checkbox-container">
                    <div class="contentfusion-checkbox">
                      <input name="includecontent" [(ngModel)]="eventDetails && eventDetails.IncludeInContentFusion" type="checkbox">
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="eventDetails && eventDetails.For_Marathon">
                <div class="basic-info__setting">
                  <h4 class="basic-info__heading setting__heading ls-subheading required-field">
                    <span class="svg-icon svg-baseline">
                      <svg fill="#BBBBBB" height="20" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path
                          d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95c-.32-1.25-.78-2.45-1.38-3.56 1.84.63 3.37 1.91 4.33 3.56zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56-1.84-.63-3.37-1.9-4.33-3.56zm2.95-8H5.08c.96-1.66 2.49-2.93 4.33-3.56C8.81 5.55 8.35 6.75 8.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95c-.96 1.65-2.49 2.93-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z" />
                      </svg>
                    </span> Select Marathon
                  </h4>
                  <div class="select-container">
                    <select class="setting__select-input select-input" name="ateScheduleList"
                      [(ngModel)]="eventDetails && eventDetails.MarathonScheduleId">
                      <option [ngValue]="0" disabled>Select the Marathon schedule</option>
                      <option [ngValue]="schedule.ID" *ngFor="let schedule of marathonScheduleList">{{schedule.Title}}
                      </option>
                    </select>
                  </div>

                </div>
              </div>
              <div *ngIf="(eventDetails && eventDetails.RestrictedAte) || participatingClients.length > 0" class="info-container__section-split participating-clients">

                    <div class="basic-info__setting">
                        <h4 class="basic-info__heading setting__heading ls-subheading ls-tooltip">
                            <div class="svg-icon svg-baseline">
                                <svg height="20" fill="#BBBBBB" viewBox="0 0 24 24">
                                    <path
                                        d="M12,13C9.33,13 4,14.33 4,17V20H20V17C20,14.33 14.67,13 12,13M12,4A4,4 0 0,0 8,8A4,4 0 0,0 12,12A4,4 0 0,0 16,8A4,4 0 0,0 12,4M12,14.9C14.97,14.9 18.1,16.36 18.1,17V18.1H5.9V17C5.9,16.36 9,14.9 12,14.9M12,5.9A2.1,2.1 0 0,1 14.1,8A2.1,2.1 0 0,1 12,10.1A2.1,2.1 0 0,1 9.9,8A2.1,2.1 0 0,1 12,5.9Z" />
                                </svg>
                            </div>
                            Participating clients:
                            <tooltip [tooltipMessage]="_emsConfig.text?.EMS_General.Ate_Restricted_Tooltip">
                            </tooltip>
                        </h4>

                        <div class="library-container">
                            <div class="list-container">
                                <div class="list">
                                    <h2 class="list__heading ls-subheading">
                                        Clients</h2>
                                    <div class="list__body">
                                        <table class="display-table">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let client of allClientsWithRestrictedAtes">
                                                    <td class="vol-title">
                                                        <div class="ls-checkbox">
                                                            <input id="{{client.ClientName+ 'all'}}" type="checkbox"
                                                                [ngModel]="client.Selected" name="{{client.ClientName+ 'all'}}"
                                                                (change)="clientSelectionHandler(client,$event)">
                                                            <label
                                                                [attr.for]="client.ClientName+ 'all'"><span></span></label>
                                                        </div>
                                                        {{client.ClientName}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <button class="btn btn--large ls-button-1" (click)="addClient()">Add</button>
                            </div>
                            <div class="list-container">
                                <div class="list">
                                    <h2 class="list__heading list__heading--field ls-subheading">
                                        Currently participating clients
                                    </h2>
                                    <div class="list__body">
                                        <table class="display-table">
                                            <thead>
                                                <tr>
                                                    <th class="ls-subheading">
                                                        Name
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let selectedClient of participatingClients">
                                                    <td class="vol-title">
                                                        <div class="ls-checkbox">
                                                            <input id="{{selectedClient.ClientName+ 'selected'}}"
                                                                type="checkbox" [ngModel]="selectedClient.Selected" name="{{selectedClient.ClientName+ 'selected'}}"
                                                                (change)="participatedClientHandler(selectedClient,$event)">
                                                            <label
                                                                [attr.for]="selectedClient.ClientName + 'selected'">
                                                                <span></span></label>
                                                        </div>
                                                        {{selectedClient.ClientName}}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="list-btn-container ">
                                    <button class="btn ls-button-1" (click)="removeClient()">Remove</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="form-button-container">
              <button
                (click)="openConfirmDialog('eventDetails', 'updateEvent',eventDetails, _emsConfig.text?.EMS_General.Confirm_Create_Update_Message)"
                type="button" class="btn module-submit">
                Submit
              </button>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>
