// Angular Things

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmsConfig } from '.././shared/emsConfig';

// Classes
import { VolumeListItem } from '../shared/VolumeListItem';
import { VolumeDetailItem } from '../shared/VolumeDetailItem';
import { SimpleVolume } from '../shared/SimpleVolume';
import { Expert } from '../shared/expert';
import { ModuleItem } from '../shared/ModuleItem';
import { QuizItem } from './QuizItem';
import { QuizQuestion } from './QuizQuestion';
import { Inventory } from '../shared/inventory';
import { LanguageItem } from '../shared/languageItem';
import { TipsheetItem } from './tipsheetItem';
import { PollJson } from 'app/shared/pollJson';
import { CanadaReportTemplateItem } from './canadaReportTemplateItem';
import { CanadaReportBuildItem } from './canadaReportBuildItem';
import { ExpertReviewItem } from 'app/shared/expert-review-item';
import { HelpSection } from './HelpSection';
import { HelpQuestion } from './HelpQuestion';
import { SiteTextItem } from 'app/shared/objects/siteTextItem';
import { SiteTextPage } from 'app/shared/objects/siteTextPage';
import { ReplaySubject } from 'rxjs';
import { ExpertSpeciality } from 'app/shared/ExpertSpeciality';
import { ExpertAssignments } from 'app/shared/ExpertAssignments';
import { ExpertBioVideo } from './ExpertBioVideoItem';
import { ExpertBiography } from 'app/shared/objects/expertBiography';
import { CampaignDetails } from './details/cms-campaign/campaign-details';
import { SubtitleLang } from 'app/shared/SubtitleLang';
import { VolumeSubtitle } from 'app/shared/VolumeSubtitle';
import { cpdTitle } from 'app/shared/objects/cpdTitle';
import { SubCategory } from 'app/shared/SubCategory';
import { AITemplate } from 'app/shared/aiTemplate';

@Injectable()
export class CmsService {
  // ******VOLUME METHODS********
  private _volumeURL = this.emsConfig.apiEndpoint + "/assetListings/getallvolumes";
  private _simpleVolumeURL = this.emsConfig.apiEndpoint + "/assetListings/getsimplevolumes";
  private _editVolumeURL = this.emsConfig.apiEndpoint + "/assetAdmin/insertorupdatevolume";
  private _getEmsVolumeURL = this.emsConfig.apiEndpoint + "/assetAdmin/getemsvolumebyid";
  private _getInventoriesURL = this.emsConfig.apiEndpoint + "/assetAdmin/getallinventories";
  private _getInventoryStatusesURL = this.emsConfig.apiEndpoint + "/assetAdmin/getallinventorystatuses";
  private _editEmsVolumeURL = this.emsConfig.apiEndpoint + "/assetAdmin/insertorupdateemsvolume";
  private _keywordsURL = this.emsConfig.apiEndpoint + "/assetAdmin/getallkeywords";
  private _relatedVolumeURL = this.emsConfig.apiEndpoint + "/assetAdmin/getrelatedvolumes?langcode=";
  private _groupedVolumesURL = this.emsConfig.apiEndpoint + "/assetAdmin/getvolumesgroupedbycategory?langcode=";
  private _modifyRelatedVolumeUrl = this.emsConfig.apiEndpoint + "/assetAdmin/modifyrelatedvolume";
  private _relatedWebchatURL = this.emsConfig.apiEndpoint + "/assetAdmin/getrelatedwebchats";
  private _groupedWebchatURL = this.emsConfig.apiEndpoint + "/assetAdmin/getwebchatsgroupedbycategory";
  private _modifyRelatedWebchatUrl = this.emsConfig.apiEndpoint + "/assetAdmin/modifyrelatedwebchat";
  private _retireVolumePageURL = this.emsConfig.apiEndpoint + '/assetAdmin/retirevolume';
  private _volumeSubtitleURL = this.emsConfig.apiEndpoint + "/assetAdmin/getsubtitlesbyvolume";
  private _getModuleListForSubtitlingURL = this.emsConfig.apiEndpoint + '/assetAdmin/getmodulelistforsubtitling';
  private _addUpdateVolumeSubtitlesURL = this.emsConfig.apiEndpoint + "/assetAdmin/addupdatevolumesubtitles";
  private _deleteVolumeSubtitleInfosURL = this.emsConfig.apiEndpoint + "/assetAdmin/deletevolumesubtitleinfo";
  private _getVideoParticipationDetailsURL = this.emsConfig.apiEndpoint + "/assetListings/getvideoparticipationdetails";
  private _getVideoParticipationCountURL = this.emsConfig.apiEndpoint + "/assetListings/getvideoparticipationcount";
  private _onPointURL = this.emsConfig.apiEndpoint + "/assetAdmin/getassociatedonpointsbyvolumeid";
  // ******MODULES METHODS********
  private _modulesURL = this.emsConfig.apiEndpoint + "/assetListings/getallmodules";
  private _moduleDetailsURL = this.emsConfig.apiEndpoint + '/assetAdmin/getemsmediaclipbyid';
  private _editModuleURL = this.emsConfig.apiEndpoint + '/assetAdmin/insertorupdatemediaclip';
  private _reuploadAmazonModule = this.emsConfig.apiEndpoint + '/assetAdmin/reuploadamazonvideo';
  private __getBrightcoveID = this.emsConfig.apiEndpoint + '/assetAdmin/getbrightcoveidforvideo';
  private _subtitleURL = this.emsConfig.apiEndpoint + "/assetAdmin/getallsubtitlesbyclipid";
  private _addSubtitleURL = this.emsConfig.apiEndpoint + '/assetAdmin/addupdatesubtitleforclip';
  private _bulkUpdateSubtitleURL = this.emsConfig.apiEndpoint + '/assetAdmin/bulkupdatesubtitlesforclip';
  private _deleteSubtitleFileURL = this.emsConfig.apiEndpoint + '/assetAdmin/deletesubtitlefile';
  private _deleteEntireSubtitleInfosURL = this.emsConfig.apiEndpoint + '/assetAdmin/deleteentiresubtitleinfo';
  private _subCategoriesByCategoryIdURL = this.emsConfig.apiEndpoint + '/assetAdmin/getallsubcategoriesbycategory';
  private _getAWSPresignedURL = this.emsConfig.apiEndpoint + '/assetAdmin/getawspresignedurl';
  private _getMatchedVideosURL = this.emsConfig.apiEndpoint + "/assetAdmin/getmatchedvideosbyvolumeid";
  // ******IQ METHODS********
  private _iqURL = this.emsConfig.apiEndpoint + '/assetListings/getalliqquizzes';
  private _iqDetailsURL = this.emsConfig.apiEndpoint + '/assetAdmin/getquizbyid';
  private _iqUpdateQuestionURL = this.emsConfig.apiEndpoint + '/assetDetails/insertUpdateIqQuestion';
  private _iqUpdateAnswerURL = this.emsConfig.apiEndpoint + '/assetDetails/insertupdateiqanswer';
  private _iqDeleteQuestionURL = this.emsConfig.apiEndpoint + '/assetDetails/deleteiqquestion';
  private _iqDeleteAnswerURL = this.emsConfig.apiEndpoint + '/assetDetails/deleteiqanswer';

  // ******EXPERTS METHODS********
  private _allExpertsURL = this.emsConfig.apiEndpoint + '/expertAdmin/getallexperts';
  private _expertDetailsURL = this.emsConfig.apiEndpoint + '/expertAdmin/getadminexpertbyid';
  private _editExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/insertorupdateexpert';
  private _reviewExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/getexpertsformailout';
  private _requestExpertMailoutURL = this.emsConfig.apiEndpoint + '/expertAdmin/requestexpertreviewmailout';
  private _requestExpertMailoutAZURL = this.emsConfig.apiEndpoint + '/expertAdmin/requestexpertreviewmailoutaz';
  private _insertExpertSpeciaityURL = this.emsConfig.apiEndpoint + '/expertAdmin/insertexpertspeciality';
  private _deleteExpertSpecialityURL = this.emsConfig.apiEndpoint + '/expertAdmin/deleteexpertspeciality';
  private _getAllExpertSpecialitiesURL = this.emsConfig.apiEndpoint + '/expertAdmin/getallexpertspecialities';
  private _deleteContractFileURL = this.emsConfig.apiEndpoint + '/expertAdmin/deletecontractpdf';
  private _updateContractFilesURL = this.emsConfig.apiEndpoint + '/expertAdmin/updatecontractfiles';
  private _getContractFileNameURL = this.emsConfig.apiEndpoint + '/expertAdmin/getcontractfilenames?ID=';
  private _addExpertAssignmentURL = this.emsConfig.apiEndpoint + '/expertAdmin/insertexpertassignment';
  private _getExpertAllAssignmentsURL = this.emsConfig.apiEndpoint + '/expertAdmin/getexpertallassignments?ID=';
  private _deleteExpertAssignmentURL = this.emsConfig.apiEndpoint + '/expertAdmin/deleteexpertassignment';
  private _updateExpertAssignmentURL = this.emsConfig.apiEndpoint + '/expertAdmin/updateexpertassignment';
  private _getVolumesByExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/getvolumesbyspeaker?ID=';
  private _addSpecialityforExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/addspecialityforexpert';
  private _getSpecialitiesforExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/getspecialitiesforexpert?ID=';
  private _updateSpecialityforExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/updatespecialityforexpert';
  private _deleteSpecialityforExpertURL = this.emsConfig.apiEndpoint + '/expertAdmin/deletespecialityforexpert';
  private _expertBioVideosURL = this.emsConfig.apiEndpoint + '/expertAdmin/getbiovideos';
  private _updateExpertBioVideosURL = this.emsConfig.apiEndpoint + '/expertAdmin/addupdateexpertbiovideo';
  private _deleteExpertBioVideosURL = this.emsConfig.apiEndpoint + '/expertAdmin/deleteexpertbiovideo';
  private _retransferExpertBioVideosURL = this.emsConfig.apiEndpoint + '/expertAdmin/flagexpertvideofortransfer';
  private _getExpertBiographyListURL=this.emsConfig.apiEndpoint + '/expertAdmin/getexpertbiographylist';
  private  _addUpdateExpertBiography= this.emsConfig.apiEndpoint + '/expertAdmin/insertupdateexpertbiography';
  private _deleteExpertBiographyByLangURL = this.emsConfig.apiEndpoint + '/expertAdmin/deleteexpertbiographybylang'
  // ******IMAGES METHODS********
  private _unprocessedImagesUrl = this.emsConfig.apiEndpoint + '/assetAdmin/getunprocessedimages';
  private _imageQueueUrl = this.emsConfig.apiEndpoint + '/assetAdmin/addimagetoqueue';
  private _imageQueueUrlRemove = this.emsConfig.apiEndpoint + '/assetAdmin/removeimagefromqueue';
  private _imageResizingConfig = this.emsConfig.apiEndpoint + '/assetAdmin/getfolderwithimageresizingconfigs';
  private _removeImageFromAWS = this.emsConfig.apiEndpoint + '/assetAdmin/removeprocessedimagefromaws';
  
  //****** AI calls */
  private _extractCategories = this.emsConfig.apiEndpoint + '/ai/extractCategories';
  private _extractTags = this.emsConfig.apiEndpoint + '/ai/extractTags';
  private _extractTemplates = this.emsConfig.apiEndpoint + '/ai/extractTemplates';
  private _questionContent = this.emsConfig.apiEndpoint + '/ai/questionContent';
  private _updateTemplate = this.emsConfig.apiEndpoint + '/ai/updateTemplate';
  private _deleteTemplate = this.emsConfig.apiEndpoint + '/ai/deleteAITemplate';
  private _addTemplate = this.emsConfig.apiEndpoint + '/ai/addAITemplate';    

  // ******REPORTING METHODS********
  private _cmsReportsUrl = this.emsConfig.apiEndpoint + '/assetAdmin/getallcmsreports';

  // ******INVENTORY METHODS********
  private _inventoryForVolumesURL = this.emsConfig.apiEndpoint + '/inventory/getInventoriesForVolume';
  private _insertDeleteInventoryURL = this.emsConfig.apiEndpoint + '/inventory/insertDeleteInventory';

  // ******MANAGE SITE TEXT METHODS********
  private _allPagesURL = this.emsConfig.apiEndpoint + '/language/getPageList';
  private _addPageURL = this.emsConfig.apiEndpoint + '/language/insertPage';
  private _allResourcesByPageID = this.emsConfig.apiEndpoint + '/language/getResourceListByPageID';
  private _resourceDetailsURL = this.emsConfig.apiEndpoint + '/language/getResourcedetails'; // TODO: Flag for delete
  private _searchResultURL = this.emsConfig.apiEndpoint + '/language/getSearchResult';
  private _insertUpdateResourceURL = this.emsConfig.apiEndpoint + '/language/insertUpdateResources'; // TODO: flag for delete
  private _siteTextByResourceGroupIdURL = this.emsConfig.apiEndpoint + '/language/getsitetextbyresourcegroupid';
  private _allLanguagesURL = this.emsConfig.apiEndpoint + '/language/getlanguages';
  private _addUpdateSiteTextURL = this.emsConfig.apiEndpoint + '/language/addupdatesitetext';
  private _deleteSiteTextURL = this.emsConfig.apiEndpoint + '/language/deletesitetext';

  // ******TIPSHEET METHODS********
  private _allTipsheetsURL = this.emsConfig.apiEndpoint + '/assetListings/gettipsheets';
  private _tipsheetByClientURL = this.emsConfig.apiEndpoint + '/assetAdmin/gettipsheetsbyclient';
  private _tipsheetDetailURL = this.emsConfig.apiEndpoint + '/assetAdmin/gettipsheetbyid';
  private _tipsheetByVolIdURL = this.emsConfig.apiEndpoint + '/assetAdmin/gettipsheetbyvolumeid';
  private _insertUpdateTipsheetURL = this.emsConfig.apiEndpoint + '/assetAdmin/addupdatetipsheet';

  // ******TAGS METHODS********
  private _videoTagListUrl = this.emsConfig.apiEndpoint + '/assetadmin/getvideotaglist';
  private _tagListSubject = new ReplaySubject<any[]>(1);
  private _currentModuleInCache = -1;


  // ******LifeSpeak Poll METHODS********
  private _pollJsonGetAllPollURL = this.emsConfig.apiEndpoint + '/polljson/getpolllist';
  private _pollJsonGetPollbyIdURL = this.emsConfig.apiEndpoint + '/polljson/polljsongetpollbyid?pollId=';
  private _pollJsonAddUpdatePollURL = this.emsConfig.apiEndpoint + '/polljson/polljsoncreateupdate';
  private _pollJsonDeletePollURL = this.emsConfig.apiEndpoint + '/polljson/removepoll?id=';

  // ******Canada Report Templates METHODS********
  private _allReportTemplatesURL = this.emsConfig.apiEndpoint + '/assetListings/getreporttemplates';
  private _templateByIdURL = this.emsConfig.apiEndpoint + '/assetAdmin/getreporttemplatebyid';
  private _updateTemplateByIdURL = this.emsConfig.apiEndpoint + '/assetAdmin/updatereporttemplate';
  private _canadaDepartmentsURL = this.emsConfig.apiEndpoint + '/assetAdmin/getcanadadepartments';
  private _buildCanadaReportURL = this.emsConfig.apiEndpoint + '/assetAdmin/buildCanadaReport';

  // ******Help METHODS********
  private _allHelpSectionsURL = this.emsConfig.apiEndpoint + '/help/gethelpsectionlistings';
  private _getSingleHelpSectionURL = this.emsConfig.apiEndpoint + '/help/gethelpsection?id=';
  private _insertUpdateHelpSectionURL = this.emsConfig.apiEndpoint + '/help/addupdatehelpsection';

  private _getHelpQuestionsURL = this.emsConfig.apiEndpoint + '/help/gethelpquestionsbysection';
  private _insertUpdateHelpQuestionURL = this.emsConfig.apiEndpoint + '/help/addupdatehelpquestion';
  private _deleteHelpQuestionURL = this.emsConfig.apiEndpoint + '/help/deletehelpquestion';

  // ******Campaign METHODS********
  private _insertUpdateCampaignURL = this.emsConfig.apiEndpoint + '/Campaign/insertupdatecampaign';
  private _deleteCampaignItemURL = this.emsConfig.apiEndpoint + '/Campaign/deletecampaignitem';

   // ******SubCategory METHODS********
   private _addUpdateSubCategoryURL = this.emsConfig.apiEndpoint + '/assetListings/addupdatesubcategory';
  private _subCategoryByIdURL = this.emsConfig.apiEndpoint + '/assetListings/getsubcategorybyid';
  private _getAllSubcategoriesURL = this.emsConfig.apiEndpoint + '/assetListings/getallsubcategories';
  private _updateSubCategorySiteVisibilityURL = this.emsConfig.apiEndpoint + '/assetListings/updatesubcategorysitevisibility';
  private _deleteSubCategoryURL = this.emsConfig.apiEndpoint + '/assetListings/deletesubcategory';

  // ******OLS MIGRATION METHODS********
  private _logMigration = this.emsConfig.apiEndpoint + '/olsMigration/logmigration'
  private _uploadExpertsToContentFusion = this.emsConfig.apiEndpoint + '/olsMigration/uploadexpertstocontentfusion'
  private _uploadTaxonomyToContentFusion = this.emsConfig.apiEndpoint + '/olsMigration/uploadtaxonomytocontentfusion'

  // related volumes properties are stored here, so they can be used by different components
  // volumes formatted for page
  public GroupedVolumes: any[] = [];
  public GroupedWebchats: any[] = [];

  // volumes formatted for dropdown list
  public VolumesForDropdown: any[] = [];
  public WebchatsForDropdown: any[] = [];
  public RelatedCategoryList: any[] = [];
  public RelatedWebchatList: any[] = [];
  public pageList = { ResourceKey: '', PageName: '' };
  constructor(private _httpClient: HttpClient, private emsConfig: EmsConfig) { }

  private get cacheBust(): string {
    // get list of additional categories by volume ID -- add a cachebuster to retrieve make a fresh request
    const cacheBuster: number = Math.floor(Math.random() * 500000 + 1);
    return cacheBuster.toString();
  }

  private get cacheBuster(): string {
    // get list of additional categories by volume ID -- add a cachebuster to retrieve make a fresh request
    const cacheBuster: number = Math.floor(Math.random() * 500000 + 1);
    return `_cb=${cacheBuster.toString()}`;
  }

  // ===============
  // Volume Methods
  // ===============

  // get list of all volumes and onpoints
  // status options: 'active', 'testing', 'QA', 'retired'
  // status of null returns ALL
  getAllVolumes(
    isOnpoint: boolean,
    lang: string,
    status: string = null,
    categoryId = -1,
    allVideos = false
  ): Observable<VolumeListItem[]> {
    const params = new HttpParams()
      .set('isOnpoint', isOnpoint.toString())
      .set('language', lang)
      .set('status', status)
      .set('categoryId', categoryId.toString())
      .set('allVideos', allVideos.toString());
    return this._httpClient.get<VolumeListItem[]>(this._volumeURL, {
      params: params
    });
  }

  // get list of all simple volumes
  getSimpleVolumes(lang: string, onPoint: boolean): Observable<SimpleVolume[]> {
    const params = new HttpParams()
      .set('onPoint', onPoint.toString())
      .set('language', lang);
    return this._httpClient.get<SimpleVolume[]>(this._simpleVolumeURL, {
      params: params
    });
  }

  addUpdateVolume(volume: VolumeListItem): Observable<any> {
    const body = JSON.stringify(volume);
    return this._httpClient.post<any>(this._editVolumeURL, body);
  }

  getEmsVolume(
    id: number,
    additionalData: boolean
  ): Observable<VolumeDetailItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('includeAdditionalData', additionalData.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<VolumeDetailItem>(this._getEmsVolumeURL, {
      params: params
    });
  }

  addUpdateEmsVolume(volume: VolumeListItem): Observable<any> {
    const body = JSON.stringify(volume);
    return this._httpClient.post<any>(this._editEmsVolumeURL, body);
  }

  getInventories(): Observable<any> {
    const params = new HttpParams().set('_cb', this.cacheBust);
    return this._httpClient.get<VolumeDetailItem>(this._getInventoriesURL, {
      params: params
    });
  }

  getInventoryStatuses(): Observable<any> {
    const params = new HttpParams().set('_cb', this.cacheBust);
    return this._httpClient.get<VolumeDetailItem>(this._getInventoryStatusesURL, {
      params: params
    });
  }

  getAssociatedOnpointsByVolumeID(id: number): Observable<ModuleItem[]> {
    const params = new HttpParams().set('id', id);
    return this._httpClient.get<ModuleItem[]>(this._onPointURL, {
      params: params
    });
  }

  getAllKeywords(): Observable<any> {
    return this._httpClient.post<any>(this._keywordsURL, '');
  }

  retireVolume(id: number): Observable<any> {
    return this._httpClient.post<any>(this._retireVolumePageURL + '?id=' + id, '');
  }

   // get list of volume subtitles
   getSubtitlesByVolume(id: number): Observable<VolumeSubtitle[]> {
    const params = new HttpParams().set('id', id.toString());
    return this._httpClient.get<VolumeSubtitle[]>(this._volumeSubtitleURL, {
      params: params
    });
  }
  getVideoParticipationDetails(mediaType:string,assetid: number): Observable<any> {
    const params = new HttpParams().set('mediatype', mediaType).set('assetid', assetid.toString());
    return this._httpClient.get<any>(this._getVideoParticipationDetailsURL, {
      params: params
    });
  }
  getVideoParticipationCount(mediaType:string,assetid: number): Observable<any> {
    const params = new HttpParams().set('mediatype', mediaType).set('assetid', assetid.toString());
    return this._httpClient.get<any>(this._getVideoParticipationCountURL, {
      params: params
    });
  }

  // add or update volume subtitles
  addUpdateVolumeSubtitles(subtitle: VolumeSubtitle[]): Observable<any> {
    const body = JSON.stringify(subtitle);
    return this._httpClient.post<Boolean>(this._addUpdateVolumeSubtitlesURL, body);
  }

  // delete single volume subtitle info from DB table
  deleteVolumeSubtitleInfo(volumeid:number,id: number) {
    const body = JSON.stringify({ Key: volumeid, Value: id });
    return this._httpClient.post<any>(this._deleteVolumeSubtitleInfosURL, body);
  }

  // ===============
  // Module Methods
  // ===============

  getAllModules(lang: string): Observable<ModuleItem[]> {
    const params = new HttpParams().set('language', lang);
    return this._httpClient.get<ModuleItem[]>(this._modulesURL, {
      params: params
    });
  }

  getModuleDetails(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._moduleDetailsURL, {
      params: params
    });
  }
 // get list of subcategories by id
 getSubCategoriesByCategoryId(id: number): Observable<SubCategory[]> {
  const params = new HttpParams().set('id', id.toString());
  return this._httpClient.get<SubCategory[]>(this._subCategoriesByCategoryIdURL, {
    params: params
  });
}
  reuploadAmazonModule(id: number): Observable<any> {
    const params = new HttpParams().set('id', id.toString());
    return this._httpClient.get<any>(this._reuploadAmazonModule, {
      params: params
    });
  }

  getBrightcoveID(name: string): Observable<any> {
    const params = new HttpParams().set('name', name);
    return this._httpClient.get<any>(this.__getBrightcoveID, {
      params: params
    });
  }

  addUpdateModule(module: ModuleItem): Observable<any> {
    const body = JSON.stringify(module);
    return this._httpClient.post<any>(this._editModuleURL, body);
  }

  getAWSPresignedUrl(key: string): Observable<any> {
    const params = new HttpParams().set('key', key);
    return this._httpClient.get<any>(this._getAWSPresignedURL, { params: params });  
  }
  
  // get list of subtitles
  getAllSubtitles(id: number): Observable<SubtitleLang[]> {
    const params = new HttpParams().set('id', id.toString());
    return this._httpClient.get<SubtitleLang[]>(this._subtitleURL, {
      params: params
    });
  }
  // add or update single subtitle
  addUpdateSubtitleForClip(subtitle: SubtitleLang): Observable<any> {
    const body = JSON.stringify(subtitle);
    return this._httpClient.post<any>(this._addSubtitleURL, body);
  }

  // delete subtitle file from cloudberry
  deleteSubtitleFile(langcode: string, fileName: string): Observable<any> {
    const body = JSON.stringify({ Key: langcode, Value: fileName });
    return this._httpClient.post<any>(this._deleteSubtitleFileURL, body);
  }
  // delete single subtitle info from DB table
  // subtitleid stands for module subtitle ID
  deleteEntireSubtitleInfo(subtitle: SubtitleLang): Observable<any> {
    const body = JSON.stringify(subtitle);
    return this._httpClient.post<any>(this._deleteEntireSubtitleInfosURL, body);
  }
  // bulk update subtitles
  bulkUpdateSubtitlesForClip(subtitle: SubtitleLang[]): Observable<any> {
    const body = JSON.stringify(subtitle);
    return this._httpClient.post<any>(this._bulkUpdateSubtitleURL, body);
  }

  getMatchedVideosByVolumeID(volumeid: number): Observable<ModuleItem[]> {
    const params = new HttpParams().set('id', volumeid);
    return this._httpClient.get<ModuleItem[]>(this._getMatchedVideosURL, {
      params: params
    });
  }
  // ===========
  // iQ Methods
  // ===========

  // get list of all volumes and onpoints
  getAllIqQuizzes(): Observable<QuizItem[]> {
    return this._httpClient.get<QuizItem[]>(this._iqURL);
  }

  // get list of iq media clips for volume
  getIqQuizDetails(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._iqDetailsURL, { params: params });
  }

  // create or update iQ quiz question
  createUpdateIqQuizQuestion(question: QuizQuestion): Observable<any> {
    const body = JSON.stringify(question);
    return this._httpClient.post<any>(this._iqUpdateQuestionURL, body);
  }

  // create or update iQ quiz answers
  createUpdateIqQuizAnswer(answer: any): Observable<any> {
    const body = JSON.stringify(answer);
    return this._httpClient.post<any>(this._iqUpdateAnswerURL, body);
  }

  deleteIqQuizQuestion(id: any): Observable<any> {
    return this._httpClient.post<any>(
      this._iqDeleteQuestionURL + '?id=' + id,
      ''
    );
  }

  deleteIqQuizAnswer(id: any): Observable<any> {
    return this._httpClient.post<any>(
      this._iqDeleteAnswerURL + '?id=' + id,
      ''
    );
  }

  // ================
  // Experts Methods
  // ================

  // return a list of experts
  getAllExperts(): Observable<Expert[]> {
    return this._httpClient.get<Expert[]>(this._allExpertsURL);
  }

  getExpertDetails(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._expertDetailsURL, {
      params: params
    });
  }

  addUpdateExpert(expert: Expert): Observable<any> {
    const body = JSON.stringify(expert);
    return this._httpClient.post<any>(this._editExpertURL, body);
  }

  getExpertsForReviewMailout(): Observable<ExpertReviewItem[]> {
    return this._httpClient.get<ExpertReviewItem[]>(this._reviewExpertURL);
  }

  requestExpertReviewMailout(request: any): Observable<number> {
    return this._httpClient.post<number>((this.emsConfig.infraLocation == 'azure')?this._requestExpertMailoutAZURL:this._requestExpertMailoutURL, request);
  }

  insertExpertSpeciaity(categoryID: number, speciality: string): Observable<any> {
    const body = JSON.stringify({ Key: categoryID, Value: speciality });
    return this._httpClient.post<any>(this._insertExpertSpeciaityURL, body);
  }

  deleteExpertSpeciality(categoryID: number, specialityID: number): Observable<any> {
    const body = JSON.stringify({ Key: categoryID, Value: specialityID });
    return this._httpClient.post<any>(this._deleteExpertSpecialityURL, body);
  }

  // return a list of expert speciality
  getAllExpertSpeciality(): Observable<ExpertSpeciality[]> {
    return this._httpClient.get<ExpertSpeciality[]>(this._getAllExpertSpecialitiesURL);
  }

  addIndividualExpertSpeciality(id: number, SpecialityID: number): Observable<any> {
    const body = JSON.stringify({ Key: id, Value: SpecialityID });
    return this._httpClient.post<any>(this._addSpecialityforExpertURL, body);
  }

  updateIndividualExpertSpeciality(id: number, speciality: ExpertSpeciality): Observable<any> {
    const body = JSON.stringify({ Key: id, Value: speciality });
    return this._httpClient.post<any>(this._updateSpecialityforExpertURL, body);
  }

  deleteIndividualExpertSpeciality(id: number, specialityID: number): Observable<any> {
    const body = JSON.stringify({ Key: id, Value: specialityID });
    return this._httpClient.post<any>(this._deleteSpecialityforExpertURL, body);
  }

  // delete contract file from cloudberry
  deleteContractFile(id: number, fileName: string): Observable<any> {
    const body = JSON.stringify({ Key: id, Value: fileName });
    return this._httpClient.post<any>(this._deleteContractFileURL, body);
  }

  // update contract file name in db after delete/ upload
  updateContractFiles(id: number, fileName: string): Observable<any> {
    const body = JSON.stringify({ Key: id, Value: fileName });
    return this._httpClient.post<any>(this._updateContractFilesURL, body);
  }

  // gets contract file name for client
  getContractFileName(id: number): Observable<string> {
    return this._httpClient.get<any>(this._getContractFileNameURL + id);
  }

  insertExpertAssignment(assignment: ExpertAssignments): Observable<any> {
    const body = JSON.stringify(assignment);
    return this._httpClient.post<any>(this._addExpertAssignmentURL, body);
  }

  getExpertAllAssignments(id: number): Observable<ExpertAssignments[]> {
    return this._httpClient.get<ExpertAssignments[]>(this._getExpertAllAssignmentsURL + id);
  }

  deleteExpertAssignment(assignment: ExpertAssignments): Observable<any> {
    const body = JSON.stringify(assignment);
    return this._httpClient.post<any>(this._deleteExpertAssignmentURL, body);
  }

  getVolumesByExpert(id: number): Observable<any> {
    return this._httpClient.get<any>(this._getVolumesByExpertURL + id);
  }

  getIndividualExpertSpeciality(id: number): Observable<ExpertSpeciality[]> {
    return this._httpClient.get<ExpertSpeciality[]>(this._getSpecialitiesforExpertURL + id);
  }

  updateExpertAssignment(assignment: ExpertAssignments): Observable<any> {
    const body = JSON.stringify(assignment);
    return this._httpClient.post<any>(this._updateExpertAssignmentURL, body);
  }

  getExpertBioVideos(id: number): Observable<any> {
    const params = new HttpParams()
      .set('expertId', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._expertBioVideosURL, { params: params });
  }

  addUpdateExpertBioVideo(video: ExpertBioVideo): Observable<any> {
    const body = JSON.stringify(video);
    return this._httpClient.post<any>(this._updateExpertBioVideosURL, body);
  }

  deleteExpertBioVideo(id: any): Observable<any> {
    return this._httpClient.post<any>(this._deleteExpertBioVideosURL + '?id=' + id, '');
  }

  retransferExpertBioVideo(id: any): Observable<any> {
    return this._httpClient.get<any>(this._retransferExpertBioVideosURL + '?id=' + id);
  }

    // get list of expert biography in different languages
    getExpertBiographyList(expertid: number): Observable<ExpertBiography[]> {
      const params = new HttpParams()
        .set('expertid', expertid.toString());
      return this._httpClient.get<ExpertBiography[]>(this._getExpertBiographyListURL, {
        params: params
      });
    }

    addUpdateExpertBiography(bio: ExpertBiography): Observable<any> {
      const body = JSON.stringify(bio);
      return this._httpClient.post<any>(this._addUpdateExpertBiography, body);
    }

    deleteExpertBiographyByLan(expertID: number, language: string, isMainlanguage:boolean): Observable<any> {
      return this._httpClient.post<any>(this._deleteExpertBiographyByLangURL + '?expertid=' + expertID + '&language='+ language + '&ismainlanguage='+isMainlanguage,'');
    }

  // ========================
  // Related Volumes Methods
  // ========================

  getRelatedVolumes(lang: string): Observable<any[]> {
    return this._httpClient.get<any[]>(this._relatedVolumeURL + lang);
  }

  getVolumesGroupedByCategory(lang: string): Observable<any[]> {
    return this._httpClient.get<any[]>(this._groupedVolumesURL + lang);
  }

  modifyRelatedVolumes(sourceId: number, relatedId: number, originalId: number): Observable<any> {
    const modRequest = {
      OriginalVolumeID: originalId,
      RelatedVolumeID: relatedId,
      SourceVolumeID: sourceId
    };
    const body = JSON.stringify(modRequest);
    return this._httpClient.post<any>(this._modifyRelatedVolumeUrl, body);
  }

  getGroupedVolumes(lang: string, callback: Function): void {
    this.GroupedVolumes = [];
    this.VolumesForDropdown = [];
    this.getVolumesGroupedByCategory(lang).subscribe(result => {
      result.forEach(category => {
        const id = category.CategoryID;
        const name = category.CategoryName;
        const volumes: any[] = JSON.parse(category.Volumes);
        const liveVolumes = volumes.filter(volume => volume.stat === 'live');
        this.VolumesForDropdown.push({ id: id, name: name, volumes: liveVolumes });
        volumes.forEach(volume => {
          this.GroupedVolumes.push({
            categoryId: id,
            categoryName: name,
            volumeId: volume.ID,
            volumeName: volume.vs,
            status: volume.stat
          });
        });
      });
      callback.call(this, lang);
    });
  }

  public getRelatedVolumesFromApi(lang): void {
    this.getRelatedVolumes(lang).subscribe(result => {
      this.RelatedCategoryList = [];
      result.forEach(volume => {
        let relatedIds: number[] = volume.Value
          .split(',')
          .map(item => (item === '' ? -1 : parseInt(item)));
        const sourceName = this.GroupedVolumes.find(
          item => item.volumeId === volume.Key
        ).volumeName;
        this.RelatedCategoryList.push({
          SourceId: volume.Key,
          SourceName: sourceName,
          RelatedIds: relatedIds
        });
      });
    });
  }

  // ========================
  // Related Webchat Methods
  // ========================

  getRelatedWebchats(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._relatedWebchatURL);
  }

  getWebchatsGroupedByCategory(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._groupedWebchatURL);
  }

  modifyRelatedWebchats(
    sourceId: number,
    relatedId: number,
    originalId: number
  ): Observable<any> {
    const modRequest = {
      OriginalVolumeID: originalId,
      RelatedVolumeID: relatedId,
      SourceVolumeID: sourceId
    };
    const body = JSON.stringify(modRequest);
    console.log(body);
    return this._httpClient.post<any>(this._modifyRelatedWebchatUrl, body);
  }

  getGroupedWebchats(): void {
    this.GroupedWebchats = [];
    this.WebchatsForDropdown = [];
    this.getWebchatsGroupedByCategory().subscribe(result => {
      result.forEach(category => {
        const id = category.CategoryID;
        const name = category.CategoryName;
        const chats: any[] = JSON.parse(category.Chats);
        this.WebchatsForDropdown.push({ id: id, name: name, webchats: chats });
        chats.forEach(chat => {
          this.GroupedWebchats.push({
            categoryId: id,
            categoryName: name,
            eventId: chat.EventID,
            eventTopic: chat.EventTopic
          });
        });
      });
      console.log(result);
      this.getGroupedVolumes('EN', this.getRelatedWebchatsFromApi);
    });
  }

  private getRelatedWebchatsFromApi(lang: string): void {
    this.getRelatedWebchats().subscribe(result => {
      this.RelatedWebchatList = [];
      result.forEach(volume => {
        let relatedIds: number[] = volume.Value
          .split(',')
          .map(item => (item === '' ? -1 : parseInt(item)));
        const sourceName = this.GroupedVolumes.find(
          item => item.volumeId === volume.Key
        ).volumeName;
        this.RelatedWebchatList.push({
          SourceId: volume.Key,
          SourceName: sourceName,
          RelatedIds: relatedIds
        });
      });
      console.log(this.RelatedWebchatList);
    });
  }

  // ===============
  // Image Methods
  // ===============
  // get list of all volumes and onpoints
  getUnprocessedImages(): Observable<any> {
    return this._httpClient.get<any>(this._unprocessedImagesUrl);
  }

  addImageToQueue(
    itemType: string,
    itemName: string,
    username: string
  ): Observable<any> {
    return this._httpClient.post<any>(this._imageQueueUrl + '?imageType=' + itemType + '&imageName=' + itemName + '&username=' + username, '');
  }

  removeImageFromQueue(itemName: string): Observable<any> {
    return this._httpClient.post<any>(this._imageQueueUrlRemove + '?imageName=' + itemName, '');
  }

  removeAwsProcessedFileFromAWS(itemName: string): Observable<any> {
    return this._httpClient.post<any>(this._removeImageFromAWS + '?imagePathWithName=' + itemName, '');
  }
  
  // ============
  // AI AmazonQ / OPEN AI
  // ============
  // Various AI API

  getAmazonQTemplates(category: string, language: string, page: number, pageSize: number): Observable<any> {
    return this._httpClient.post<any>(this._extractTemplates + '?category='+category + '&language='+language + '&page='+page + '&pageSize='+pageSize, '');
  }

  extractTags(objectId: number, objectType: string = 'blog', moduleID: number = 0, requestId: string): Observable<any> {
    return this._httpClient.post<any>(this._extractTags + '?objectId='+objectId + '&objectType='+objectType + '&moduleId='+moduleID+'&requestId='+requestId, '');
  }
 
  extractCategories(objectId: number, onlySubcategories: boolean = false, objectType: string, moduleID: number, requestId: string ): Observable<any> {
    return this._httpClient.post<any>(this._extractCategories + '?objectId='+objectId+'&onlySubCategories='+onlySubcategories + '&objectType='+objectType + '&moduleId='+moduleID + '&requestId='+requestId, '');
  }
  
  questionContent(objectId: number, objectType: string, moduleID: number, requestId: string, question: string, sendParameters:boolean ): Observable<any> {
	  const body = {question: question};//JSON.stringify(section);
	  
    return this._httpClient.post<any>(this._questionContent + '?objectId='+objectId + '&objectType='+objectType + '&moduleId='+moduleID + '&requestId='+requestId +'&sendParameters='+sendParameters, body);
  }
  
  updateAITemplate(category: string, language: string, question: string, templateId:number ): Observable<any> {
	  const body = {question: question};//JSON.stringify(section);
	  
    return this._httpClient.put<any>(this._updateTemplate + '?category='+category + '&language='+language+'&templateId='+templateId, body);
  }
  
  deleteAITemplate(category: string, language: string, templateId:number ): Observable<any> {
    return this._httpClient.delete<any>(this._deleteTemplate + '?category='+category + '&language='+language+'&templateId='+templateId);
  }
  
  addAITemplate(info: AITemplate ): Observable<any> {
    return this._httpClient.post<any>(this._addTemplate, info);
  }

  // ===============
  // Reporting Methods
  // ===============
  // get list of all volumes and onpoints
  getCMSReports(): Observable<any> {
    return this._httpClient.get<any>(this._cmsReportsUrl);
  }

  // ===============
  // Inventory Methods
  // ===============

  getInventoryForVolumes(lang: string): Observable<Inventory[]> {
    const params = new HttpParams().set('language', lang);
    return this._httpClient.get<Inventory[]>(this._inventoryForVolumesURL, {
      params: params
    });
  }

  insertDeleteInventory(volumeTitle: string, volumeID: number, inventoryID: number, statusID: number): Observable<any> {
    return this._httpClient.post<any>(this._insertDeleteInventoryURL + '?volumeTitle=' + volumeTitle + '&volumeID=' + volumeID + '&inventoryID='
      + inventoryID + '&statusID=' + statusID, +'');
  }


  // ========================
  // Manage site text Methods
  // =========================

  // get list of all Page
  getAllPages(): Observable<LanguageItem[]> {
    return this._httpClient.get<LanguageItem[]>(this._allPagesURL);
  }

  addPage(page: SiteTextPage): Observable<any> {
    const body = JSON.stringify(page);
    return this._httpClient.post<any>(this._addPageURL, body);
  }

  // search all the resources according the language
  getSearchResult(lang: string, searchItem: string): Observable<LanguageItem[]> {
    const params = new HttpParams()
      .set('Language', lang)
      .set('SearchItem', searchItem);
    return this._httpClient.get<LanguageItem[]>(this._searchResultURL, {
      params: params
    });
  }

  // gets list of resources according to pageID
  getResourcesByPageID(id: number): Observable<any> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._allResourcesByPageID, {
      params: params
    });
  }

  // TODO: Flag for delete
  // get details of resource
  getDetailsResource(pageName: string, resourceKey: string): Observable<any> {
    const params = new HttpParams()
      .set('pageName', pageName)
      .set('resourceKey', resourceKey)
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any>(this._resourceDetailsURL, {
      params: params
    });
  }

  // add/update the resources
  addUpdateResources(languageItem: LanguageItem): Observable<any> {
    const body = JSON.stringify(languageItem);
    return this._httpClient.post<any>(this._insertUpdateResourceURL, body);
  }

  // get site text by resource key (all languages)
  getSiteTextByResourceGroupId(groupId: number): Observable<SiteTextItem[]> {
    const params = new HttpParams()
      .set('groupId', groupId.toString());
    return this._httpClient.get<SiteTextItem[]>(this._siteTextByResourceGroupIdURL, {
      params: params
    });
  }

  // get all available languages
  getLanguages(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._allLanguagesURL);
  }

  addUpdateSiteTextItem(siteTextItem: SiteTextItem): Observable<any> {
    const body = JSON.stringify(siteTextItem);
    return this._httpClient.post<any>(this._addUpdateSiteTextURL, body);
  }

  deleteSiteTextItem(siteTextItem: SiteTextItem): Observable<any> {
    const body = JSON.stringify(siteTextItem);
    return this._httpClient.post<any>(this._deleteSiteTextURL, body);
  }

  // =================
  // Tipsheet Methods
  // =================

  // get list of tipsheets by lang
  getTipsheets(lang: string): Observable<TipsheetItem[]> {
    const params = new HttpParams()
      .set('language', lang);
    return this._httpClient.get<TipsheetItem[]>(this._allTipsheetsURL, { params: params });
  }

  // get list of tipsheets by client & lang
  getTipsheetsByClient(lang: string, clientName: string): Observable<TipsheetItem[]> {
    const params = new HttpParams()
      .set('language', lang)
      .set('clientName', clientName);
    return this._httpClient.get<TipsheetItem[]>(this._tipsheetByClientURL, { params: params });
  }

  getTipsheet(id: number): Observable<TipsheetItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<TipsheetItem>(this._tipsheetDetailURL, {
      params: params
    });
  }

  getTipsheetByVolume(id: number, lang: string): Observable<TipsheetItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('tipsheetLang', lang)
      .set('_cb', this.cacheBust);
    return this._httpClient.get<TipsheetItem>(this._tipsheetByVolIdURL, {
      params: params
    });
  }

  addUpdateTipsheet(tipsheet: TipsheetItem): Observable<any> {
    const body = JSON.stringify(tipsheet);
    return this._httpClient.post<any>(this._insertUpdateTipsheetURL, body);
  }


  // ====================
  // Blog Post Methods
  // ====================

  // ******PUBLIC SITE BLOG POST METHODS*********
  private _getBlogPostsURL = 'https://lifespeak.com/wp-json/wp/v2/posts?_embed&per_page=100';

  // get list of tipsheets by lang
  getBlogPosts(lang: string, page: number): any {

    const blogEndpoint: string = `${this._getBlogPostsURL}&categories=478,551`;
    const params = new HttpParams()
      .set('lang', lang.toLowerCase())
      .set('page', page.toString())

    return this._httpClient.get<any>(blogEndpoint, { observe: 'response', params: params });
  }


  // get list of tipsheets by lang
  getCovid19BlogPosts(lang: string): any {

    let tagID = lang == "EN" ? "632" : "634";
    const blogEndpoint: string = `${this._getBlogPostsURL}&tags=${tagID}`;
    return this._httpClient.get<any>(blogEndpoint, { observe: 'response' });
  }



  // ******CPD METHODS********
  private _getCPDTitlesURL = this.emsConfig.apiEndpoint + '/cpd/gettitles';
  private _getCPDAccTypesURL = this.emsConfig.apiEndpoint + '/cpd/getacctypes?language=';
  private _getCPDVolumesByTypeURL = this.emsConfig.apiEndpoint + '/cpd/getcpdvolumesbytype';
  private _getCPDTitleStatusURL = this.emsConfig.apiEndpoint + '/cpd/getcpdtitlestatus';
  private _updateCPDTitleURL = this.emsConfig.apiEndpoint + '/cpd/updatecpdtitle';
  private _addCPDVolumeURL = this.emsConfig.apiEndpoint + '/cpd/addcpdvolume';
  private _updateCPDVolumeURL = this.emsConfig.apiEndpoint + '/cpd/updatecpdvolume';
  private _removeCPDVolumeURL = this.emsConfig.apiEndpoint + '/cpd/removecpdvolume?id=';


  // ===============
  // CPD Methods
  // ===============
  getCpdTitles(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._getCPDTitlesURL);
  }

  getCpdAccTypes(lang: string): Observable<any[]> {
    return this._httpClient.get<any[]>(this._getCPDAccTypesURL + lang);
  }

  getCpdVolumesByType(profession: string, jurisdiction: string, lang: string): Observable<any[]> {
    const params = new HttpParams()
      .set('profession', profession)
      .set('jurisdiction', jurisdiction)
      .set('language',lang);
    return this._httpClient.get<any[]>(this._getCPDVolumesByTypeURL, { params: params });
  }
  getCpdTitleStatuses(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._getCPDTitleStatusURL);
  }
  addCpdVolume(vol: any): Observable<any> {
    const body = JSON.stringify(vol);
    return this._httpClient.post<any>(this._addCPDVolumeURL, body);
  }

  updateCpdVolume(vol: any): Observable<any> {
    const body = JSON.stringify(vol);
    return this._httpClient.post<any>(this._updateCPDVolumeURL, body);
  }

  removeCpdVolume(id: number): Observable<any> {
    return this._httpClient.delete<any>(this._removeCPDVolumeURL + id);
  }
  updateCPDTitle(title: cpdTitle): Observable<any> {
    const body = JSON.stringify(title);
    return this._httpClient.post<any>(this._updateCPDTitleURL, body);
  }
  // ===============
  // Poll Methods
  // ===============
  getListOfPolls(): Observable<any[]> {
    return this._httpClient.get<any[]>(this._pollJsonGetAllPollURL);
  }
  getPollbyID(pollId: number): Observable<any> {
    return this._httpClient.get<any>(this._pollJsonGetPollbyIdURL + pollId);
  }
  addUpdatePoll(poll: PollJson): Observable<PollJson> {
    return this._httpClient.post<any>(this._pollJsonAddUpdatePollURL, poll);
  }
  deletePoll(id: number): Observable<any> {
    return this._httpClient.delete<any>(this._pollJsonDeletePollURL + id);
  }

  // ===============================
  // Canada Report Template Methods
  // ===============================

  // get list of tipsheets by lang
  getReportTemplates(): Observable<CanadaReportTemplateItem[]> {
    return this._httpClient.get<CanadaReportTemplateItem[]>(this._allReportTemplatesURL);
  }

  getReportTemplate(id: number): Observable<CanadaReportTemplateItem> {
    const params = new HttpParams()
      .set('id', id.toString())
      .set('_cb', this.cacheBust);
    return this._httpClient.get<CanadaReportTemplateItem>(this._templateByIdURL, {
      params: params
    });
  }

  updateTemplate(template: CanadaReportTemplateItem): Observable<any> {
    const body = JSON.stringify(template);
    return this._httpClient.post<any>(this._updateTemplateByIdURL, body);
  }

  // send an email message via the messaging tool API
  buildCanadaReport(buildReportItem: CanadaReportBuildItem): Observable<any> {
    const body = JSON.stringify(buildReportItem);
    return this._httpClient.post<any>(this._buildCanadaReportURL, body);
  }

  // get list of departments for dropdown
  getCanadaDepartments(lang: string): Observable<any[]> {
    const params = new HttpParams()
      .set('language', lang)
      .set('_cb', this.cacheBust);
    return this._httpClient.get<any[]>(this._canadaDepartmentsURL, {
      params: params
    });
  }

  // ===============
  // Help Methods
  // ===============

  // help sections
  getHelpSections(lang: string): Observable<HelpSection[]> {
    const params = new HttpParams()
      .set('helpLang', lang);
    return this._httpClient.get<HelpSection[]>(this._allHelpSectionsURL, { params: params });
  }

  getHelpSectionbyID(sectionId: number): Observable<HelpSection> {
    return this._httpClient.get<any>(this._getSingleHelpSectionURL + sectionId);
  }

  updateHelpSection(section: HelpSection): Observable<any> {
    const body = JSON.stringify(section);
    return this._httpClient.post<any>(this._insertUpdateHelpSectionURL, body);
  }

  // help questions
  getHelpQuestions(id: string): Observable<HelpQuestion[]> {
    const params = new HttpParams()
      .set('sectionId', id);
    return this._httpClient.get<HelpQuestion[]>(this._getHelpQuestionsURL, { params: params });
  }

  updateHelpQuestion(question: HelpQuestion): Observable<any> {
    const body = JSON.stringify(question);
    return this._httpClient.post<any>(this._insertUpdateHelpQuestionURL, body);
  }

  deleteHelpQuestion(id: number): Observable<any> {
    return this._httpClient.post<any>(this._deleteHelpQuestionURL + '?id=' + id, '');
  }


  // ===============
  // Tag Method
  // ===============
  getTagListForVideos(id: number): Observable<any[]> {

    if (this._currentModuleInCache != id) {
      const params = new HttpParams()
        .set('videoid', id.toString());
      this._httpClient.get<any>(this._videoTagListUrl, { params })
        .subscribe((tags) => {
          const tagList = JSON.parse(tags).sort((a, b) => a.Keyword.localeCompare(b.Keyword));
          this._tagListSubject.next(tagList);
          this._currentModuleInCache = id;

        });
    }
    return this._tagListSubject;

  }

  // ===============
  // Aws image processing tool methods
  // ===============
  getFolderWithImageResizingConfig(): Observable<any[]> {
    return this._httpClient.get<any>(this._imageResizingConfig);
  }


  // ===============
  // CMS Campaign Methods
  // ===============

  getCampaignList(): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + "/campaign/getcampaignlist";
    return this._httpClient.get<any>(url);
  }

  getCampaignDetails(id: number): Observable<CampaignDetails> {
    const url = this.emsConfig.apiEndpoint + "/Campaign/getcampaigndetails";
    const params = new HttpParams()
    .set('campaignID', id.toString());
    return this._httpClient.get<any>(url, { params });
  }

  getCampaignsForLinking(): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + '/Campaign/getcampaignsforlinking';
    return this._httpClient.get<any>(url);
  }

  getAssetType(lang: string): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + "/Campaign/getassettype";
    const params = new HttpParams()
    .set('language', lang);
    return this._httpClient.get<any>(url, { params });
  }

  getCategories(lang: string): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + "/Campaign/getcategories";
    const params = new HttpParams()
    .set('language', lang);
    return this._httpClient.get<any>(url, { params });
  }

  getVolumes(categoryId: number, lang: string): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + "/Campaign/getvolumes";
    const params = new HttpParams()
    .set('language', lang)
    .set('categoryId', categoryId.toString());
    return this._httpClient.get<any>(url, { params });
  }

  getAssetTitle(assetTypeId: number, volumeId: number, lang: string): Observable<any[]> {
    const url = this.emsConfig.apiEndpoint + "/Campaign/getassettitle";
    const params = new HttpParams()
    .set('language', lang)
    .set('AssetTypeId', assetTypeId.toString())
    .set('VolumeId', volumeId.toString());
    return this._httpClient.get<any>(url, { params });
  }

  insertUpdateCampaign(campaign: any): Observable<any> {
    const body = JSON.stringify(campaign);
    return this._httpClient.post<any>(this._insertUpdateCampaignURL, body);
  }

  deleteCampaignSectionDetail(campaignId: number, Id: number, isCampaignSection: boolean): Observable<any[]> {
    return this._httpClient.post<any>(this._deleteCampaignItemURL + '?CampaignID=' + campaignId + '&Id=' + Id + '&IsCampaignSection=' + isCampaignSection, '');
  }

  // get list of subcategories by id
  getSubCategoryById(id: number): Observable<SubCategory[]> {
    const params = new HttpParams().set('id', id.toString());
    return this._httpClient.get<SubCategory[]>(this._subCategoryByIdURL, {
      params: params
    });
  }

  // add or update single subcategory
  addUpdateSubCategory(subCategory: SubCategory): Observable<any> {
    const body = JSON.stringify(subCategory);
    return this._httpClient.post<any>(this._addUpdateSubCategoryURL, body);
  }

  // get all subcategories
    getAllSubcategories(): Observable<SubCategory[]> {
      const params = new HttpParams();
      return this._httpClient.get<SubCategory[]>(this._getAllSubcategoriesURL, {
        params: params
      });
    }

  // update whether subcategory will be visible on site or not 
  updateSubcategorySiteVisiblity(subCategory: SubCategory): Observable<any> {
    const body = JSON.stringify(subCategory);
    return this._httpClient.post<any>(this._updateSubCategorySiteVisibilityURL, body);
  }

  //delete subcategory
  deleteSubcategory(id: number): Observable<any> {
    return this._httpClient.post<any>(this._deleteSubCategoryURL + '?id=' + id, '');
  }

  getModuleListForSubtitling(volumeIds: any, langCodes: any): Observable<any> {
    let volumeIdsStr = volumeIds.join();
    let langCodesStr = langCodes.join();
    return this._httpClient.get<any>(this._getModuleListForSubtitlingURL + '?volumeIdsStr=' + volumeIdsStr + '&langCodesStr=' + langCodesStr);
  }

  // OLS Migration
  
  logMigration(name: string, type: string): Observable<any> {
    return this._httpClient.post<any>(this._logMigration + '?name=' + name + '&type=' + type, '');
  }
  
  uploadExpertsToContentFusion(uploadAll: boolean): Observable<any> {
    return this._httpClient.get<any>(this._uploadExpertsToContentFusion + '?uploadAll=' + uploadAll);
  }
  uploadTaxonomyToContentFusion(uploadAll: boolean): Observable<any> {
    return this._httpClient.get<any>(this._uploadTaxonomyToContentFusion + '?uploadAll=' + uploadAll);
  }
}
